import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Bloglist from "../../../components/bloglist/bloglist"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/pivonuv-beh-2021.jpg"

import ImgBlogPostGallery1 from "../../../images/blogpost/pivonuv-beh-2021-01.jpg"
import ImgBlogPostGallery2 from "../../../images/blogpost/pivonuv-beh-2021-02.jpg"
import ImgBlogPostGallery3 from "../../../images/blogpost/pivonuv-beh-2021-03.jpg"
import ImgBlogPostGallery4 from "../../../images/blogpost/pivonuv-beh-2021-04.jpg"
import ImgBlogPostGallery5 from "../../../images/blogpost/pivonuv-beh-2021-05.jpg"
import ImgBlogPostGallery6 from "../../../images/blogpost/pivonuv-beh-2021-06.jpg"
import ImgBlogPostGallery7 from "../../../images/blogpost/pivonuv-beh-2021-07.jpg"
import ImgBlogPostGallery8 from "../../../images/blogpost/pivonuv-beh-2021-08.jpg"
import ImgBlogPostGallery9 from "../../../images/blogpost/pivonuv-beh-2021-09.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Pivoňův běh 2021 - Proběhli jsme se v Píseckých horách" description="Kolega Pavel Keclík s přáteli organizuje charitativní Pivoňův běh, který letos proběhl vhorách přířodního parku jižních Čech poblíž Písku." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Pivoňův běh 2021 - Proběhli jsme se v&nbsp;Píseckých horách</h1>
			<div className="ftrs-blogpost__date">22.9.2021</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Pivoňův běh 2021 - Proběhli jsme se v Píseckých horách" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>
				
				<p>Kolega Pavel Keclík s&nbsp;přáteli organizuje charitativní <a className="ftrs-blacklink" href="https://pivonuvbeh.cz" target="_blank" rel="noreferrer">Pivoňův běh</a>, který letos proběhl v&nbsp;horách přířodního parku jižních Čech poblíž Písku. Na&nbsp;výběr byly varianty 5,&nbsp;10 nebo&nbsp;21 km, aby si zaběhali jak ostřílení běžci, tak&nbsp;začátečníci.</p>

				<p>Za každých 25 korun nad&nbsp;rámec registrace Lesy města Písku nakoupí sazeničky stromů, které pak budou vysazeny. Další spolupráce probíhá se&nbsp;záchranou stanicí Makov, kam putuje většina raněných a&nbsp;odchycených zvířat z&nbsp;Písku.</p>

				<h3 className="ftrs-h3 mt-4 mb-4">Pivoňův běh na&nbsp;sociálních sítích:</h3>

				<a aria-label="Facebook" className="ftrs-socialicon ftrs-socialicon--facebook" href="https://www.facebook.com/pivonuvbeh" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faFacebookF} />
				</a>
				<a aria-label="Instagram" className="ftrs-socialicon ftrs-socialicon--instagram ml-3" href="https://www.instagram.com/pivonuvbeh" target="_blank" rel="noreferrer">
					<FontAwesomeIcon icon={faInstagram} />
				</a>

				<h2 className="ftrs-h2 mt-4 mb-4">Kdo, nebo co je to ten Pivoň?</h2>

				<p>Písecké hory ukrývají řadu tajemství. Nejčastěji mezi návštěvníky koluje pověst o&nbsp;loupežnické bandě, která se usídlila na&nbsp;samotném vrcholu Píseckých hor. Na&nbsp;Velkém Mehelníku hodovali z&nbsp;obřího balvanu, ve&nbsp;kterém jsou dodnes patrné prohlubně, jenž měly sloužit jako misky. Loupežníci tehdy netušili, že&nbsp;největší poklad čeká přímo pod&nbsp;nimi. V&nbsp;nitru Velkého Mehelníku totiž střeží zlatý poklad duch Píseckých hor skřítek Pimon.</p>

				<h3 className="ftrs-h3 mt-4 mb-4">Pivoň</h3>

				<p>Málokdo z návštěvníků ale tuší, že&nbsp;skutečným králem celých Píseckých hor je Pivoň. Pivoň písecký (Pivonius Sabulumius) je endemitní druh žijící v&nbsp;lesích Píseckých hor, ale&nbsp;vzhledem k&nbsp;jeho plachosti, ostražitosti a&nbsp;chytrosti je téměř nemožné ho pozorovat. Spojení jeho jména s&nbsp;pivem, jak by si mnozí přáli, není na&nbsp;místě. Jako býložravec ze&nbsp;všeho nejraději spásá pivoňky a&nbsp;odtud se vzalo i&nbsp;jméno. Vzhledem na&nbsp;první pohled připomíná zajíce, ale&nbsp;mezi ušima se mu tyčí parůžky. V&nbsp;některých částech se mu proto také říká Zajdalen. Pivoň žije v&nbsp;norách, které poznáte na&nbsp;první pohled. Ve&nbsp;stropě nor svými parůžky vytváří nezaměnitelné rýhy.</p>

				<p>Další poddruhy Pivoně bylo možné pozorovat i&nbsp;v&nbsp;dalších českých lesích, ale také za&nbsp;hranicemi naší vlasti, kde žije několik příbuzných tohoto jedinečného zvířete. V&nbsp;bavorském folklóru běžně narazíte na&nbsp;zvíře jménem Wolpetinger a&nbsp;v&nbsp;Severní Americe žijí ve&nbsp;dvou izolovaných populacích v&nbsp;okolí Texasu a&nbsp;místní ho znají jako Jackalope.</p>

				<h2 className="ftrs-h2 mt-4 mb-4">Fotogalerie z Pivoňova běhu 2021</h2>

				<Row className="mt-5 ftrs-blogpost-gallery">

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery1} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery2} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery3} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery4} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery5} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery6} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery7} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery8} alt="Pivoňův běh 2021" />
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<img className="ftrs-blogpost-gallery__img" src={ImgBlogPostGallery9} alt="Pivoňův běh 2021" />
					</Col>

				</Row>

				<h2 className="ftrs-h2 mt-4 mb-4">Future Rockstars na Stravě...</h2>

				<p>Future Rockstars má i&nbsp;svůj Strava klub, jak jinak než pod&nbsp;názvem Future&nbsp;Rockstars ;) Zatím má jen pár členů, ale&nbsp;už teď se v&nbsp;něm týdně naběhá stovky kilometrů. Pokud máte účet na&nbsp;Stravě a&nbsp;chcete se k&nbsp;nám přidat, <a className="ftrs-blacklink" href="https://www.strava.com/clubs/977570" target="_blank" rel="noreferrer">vítejte v&nbsp;klubu</a>!</p>

				<h3 className="ftrs-h3 mb-4 mt-4">Strava</h3>

				<Row>

					<Col xs="12" lg="8" className="mb-4" data-sal="fade" data-sal-duration="1000">
						<p>Pokud zatím nevíte, co je to Strava, pak vězte, že je to jedna z&nbsp;nejoblíbenějších mobilních aplikací na&nbsp;sledování kondice a&nbsp;sportovních aktivit. Zaznamenáváte trasy, mapujete svou oblíbenou cyklostezku nebo běžíte a&nbsp;analyzujete svůj trénink se&nbsp;všemi možnými statistikami. A&nbsp;co víc, jedná se o&nbsp;sociální síť sportovců, takže se můžete se&nbsp;svými sportovními přáteli propojit a&nbsp;sportovat společně.</p>
						<p>Aplikace Strava je ke stažení na&nbsp;<a className="ftrs-blacklink" href="https://play.google.com/store/apps/details?id=com.strava" target="_blank" rel="noreferrer">Google&nbsp;Play</a> nebo v&nbsp;<a className="ftrs-blacklink" href="https://apps.apple.com/cz/app/strava-run-ride-training/id426826309" target="_blank" rel="noreferrer">App&nbsp;Store</a>.</p>
					</Col>

					<Col xs="12" lg="4" className="mb-5" data-sal="fade" data-sal-duration="1000">
						<iframe allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/977570/latest-rides/5221d3119e68e3d350547051887c9b55aa852518?show_rides=false' width='100%' title="Strava box"></iframe>
					</Col>

				</Row>

			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog